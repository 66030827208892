<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:enableSearch="false"
      v-bind:enablePagination="false"
      v-bind:enableEdit="false"
      v-bind:enableDelete="false"
      v-bind:enableView="false"
      v-bind:enableMultiselect="false"
      v-bind:enableDownload="true"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
      @handleDownloadData="handleDownload"
  ></list-content>
</template>

<script>
import InvoiceService from "@/api/invoices.service";
import ListContent from "@/components/ListContent";
export default {
  name: "Invoices",
  components: {ListContent},
  data() {
    return {
      tableColumns: [
        // {'key':'customer_name', 'label':'Customer Name', 'sortable':true, 'is_image': false, 'width':'250'},
        {'key':'number', 'label':'Invoice  Number', 'sortable':true, 'is_image': false, 'width':''},
        {'key':'created', 'label':'Invoice Date', 'sortable':true, 'is_image': false, 'width':'200'},
        {'key':'amount_due', 'label':'Amount', 'sortable':true, 'is_image': false, 'width':'200'},
        {'key':'status', 'label':'Status', 'sortable':true, 'is_image': false, 'width':'150'},
      ],
      pageContent: {
        'add_label': null,
        'title': 'Invoices',
        'newUri': null,
        'editUri': null,
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return InvoiceService.getInvoices(this.page, this.search).then(response => {
        this.meta = {total: response.data.length};
        this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDownload(item) {
      window.open(item.row.invoice_pdf,'_blank');
    },
    handleDelete(data) {
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
